const registrationForm = document.querySelector("[data-registration-form]");

if (registrationForm) {
    function toggleEmailForm() {
        document.querySelectorAll("[data-email-form]").forEach(form => {
            let noEmail = form.querySelector('[data-no-email]');
            let emailDetails = form.querySelector("[data-email-details]");

            noEmail.addEventListener("change", function (e) {
                emailDetails.classList.toggle("hidden", e.target.checked)
            });

        });
    }

    toggleEmailForm();

    function toggleConsentSigned(value) {
        value = value ?? document.querySelector('input[name="ConsentSigned"]:checked')?.value;
        const isConsented = value === "true";
        const details = document.getElementById("sp-consent-details");
        details?.classList.toggle("hidden", !isConsented);
    }

    toggleConsentSigned();

    const consentSignedRadios = document.querySelectorAll('input[name="ConsentSigned"]')
    consentSignedRadios.forEach(radio => radio.addEventListener('change', () => toggleConsentSigned(radio.value)));
    
    const coding = document.getElementById("coding");
    const contactInfo = document.getElementById("contact-info");
    const demographics = document.getElementById("demographics");
    const interest = document.getElementById("interest-level");
    const eligibility = document.getElementById("eligibility");
    const dataCollection = document.getElementById("data-collection");
    const studyGroup = document.getElementById("study-group");
    const consentDetails = document.getElementById("consent-details");
    const interested = document.getElementById("interested");
    const interestLevelDetails = document.getElementById("interest-level-details");
    const declinedReasons = document.getElementById("declined-reasons");

    function toggleInterest(value) {
        value = value ?? document.querySelector('input[name="InterestLevel"]:checked').value;
        interestLevelDetails.classList.toggle("hidden", value === "Unknown");
        declinedReasons.classList.toggle("hidden", value !== "NotInterested");
    }

    toggleInterest();
    const interestLevelRadios = document.querySelectorAll('input[name="InterestLevel"]')
    interestLevelRadios.forEach(radio => radio.addEventListener('change', () => toggleInterest(radio.value)));
    
    function toggleSignedConsent(value) {
        value = value ?? document.querySelector('input[name="SignedConsent"]:checked')?.value;
        const isConsented = value === "true";
        consentDetails.classList.toggle("hidden", !isConsented);
        coding?.classList.toggle("hidden", !value);
        contactInfo.classList.toggle("hidden", !isConsented);
        demographics.classList.toggle("hidden", !value);
        interest.classList.toggle("hidden", !value);
        eligibility.classList.toggle("hidden", !value);
        dataCollection?.classList.toggle("hidden", !isConsented);
        studyGroup?.classList.toggle("hidden", !value);

        const interestLevel = document.querySelector('input[name="InterestLevel"]:checked').value;
        if (isConsented && interestLevel === "Unknown") {
            interested.checked = true;
            interested.dispatchEvent(new Event('change'));
        }
    }
    toggleSignedConsent();

    const signedConsentRadios = document.querySelectorAll('input[name="SignedConsent"]')
    signedConsentRadios.forEach(radio => radio.addEventListener('change', () => toggleSignedConsent(radio.value)));


    const eligibilityDetails = document.getElementById("eligibility-details");
    const exclusions = document.getElementById("exclusions");

    function toggleEligibility(value) {
        value = value ?? document.querySelector('input[name="EligibilityStatus"]:checked').value;
        eligibilityDetails.classList.toggle("hidden", value === "Pending");
        exclusions.classList.toggle("hidden", value !== "Ineligible");
    }

    toggleEligibility();
    const eligibilityRadios = document.querySelectorAll('input[name="EligibilityStatus"]')
    eligibilityRadios.forEach(radio => radio.addEventListener('change', () => toggleEligibility(radio.value)));

    const baseline = document.getElementById("baseline");

    function toggleBaseline() {
        const protocolID = document.querySelector('input[name="ProtocolID"]:checked')?.value ?? null;
        baseline?.classList.toggle("hidden", protocolID === "");
    }

    toggleBaseline();
    const baselineRadios = document.querySelectorAll('input[name="ProtocolID"]')
    baselineRadios.forEach(radio => radio.addEventListener('change', () => toggleBaseline(radio.value)));

    const manual = document.getElementById("manual");
    const manualTitle = document.getElementById("manual-title");
    const automatic = document.getElementById("automatic");
    const automaticTitle = document.getElementById("automatic-title");

    function siteChanged(radio) {
        radio = radio ?? document.querySelector('input[name="SiteID"]:checked');
        if (radio === null) return;
        let title = radio.dataset.codingTitle ?? "Site Specific Patient ID";
        manualTitle.innerHTML = title;
        automaticTitle.innerHTML = `Assign ${title}`;

        const style = radio.dataset.codingStyle;
        manual.classList.toggle("hidden", style !== "manual");
        automatic.classList.toggle("hidden", style !== "automatic");
    }

    siteChanged();
    const siteRadios = document.querySelectorAll('input[name="SiteID"]')
    siteRadios.forEach(radio => radio.addEventListener('change', () => siteChanged(radio)));
}
